  @keyframes cf3FadeInOut {
      0% {
          opacity: 1;
      }

      45% {
          opacity: 1;
      }

      55% {
          opacity: 0;
      }

      100% {
          opacity: 0;
      }
  }

  .top {
      animation-name: cf3FadeInOut;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-duration: 10s;
      animation-direction: alternate;
  }

.item-enter {
    opacity: 0.01;
}

.item-enter-active {
    opacity: 1;
    transition: transform 0.8s 0s linear;
    transform: scale(1.5)
}

.item-exit {
    opacity: 1;
}

.item-exit-active {
    opacity: 0;
    transition: opacity 800ms linear;
}

.slide-left {
    -webkit-animation: slide-left 0.5s ease-in both;
    animation: slide-left 0.5s ease-in both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-5-22 15:42:14
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
}

@keyframes slide-left {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(-100px);
        transform: translateX(-100px);
    }
}

.slide {
    position: absolute;
    /* height: au; */
    right: 0;
    /* width: 60vw; */
    /* height: 100%; */
    /* width: 60vw; */
}

.slide-container {
    position: relative;
    top: 15vh;
    width: 75vw;
    height: 70vh;
    overflow: hidden;
}

/* .inline-div {
    position: relative;
    display: inline;
    width: 60vw;
    height: 70vh;
} */

.slide-filter {
    position: absolute;
    background: rgba(0, 0, 0, 0.54);
    width: 50%;
    right: 0;
    z-index: 10;
    height: 100%;
}

.slogan {
    /* position: absolute; */
    top: 20vh;
    width: 75vw;
    max-height: 70vh;
    overflow: hidden;
    z-index: 15;
}

.slide-slogan-1 {
    position: absolute;
    top: 35vh;
    right: 15vw;
    z-index: 15;
}

.slide-slogan-2 {
    position: absolute;
    top: 50vh;
    right: 8vw;
    z-index: 15;
}

.slide-text {
    position: absolute;
    top: 70vh;
    right: 9vw;
    font-weight: 500;
    font-size: '0.9rem';
    line-height: 29px;
    text-align: center;
    letter-spacing: 0.43em;
    color: #ECECEC;
    z-index: 15;
}

.news-session {
    background-color: #fdfdfd;
    min-height: 100vh;
    width: 100wh;
}

.business-hotel-session {
    background-color: #1C2440;
    min-height: 100vh;
    text-align: end;
}

.garden-hotel-session {
    background-color: #D7A253;
    min-height: 100vh;
    text-align: end;
}

.scroll-line {
    position: absolute;
    width: 53px;
    height: 0px;
    left: 142px;
    bottom: 10vh;
    border: 1px solid #DDBB78;
    transform: rotate(90deg);
}

.service-img {
    width: 500px;
    height: auto;
}

.headline-div{
    background: #DDBB78;
    height: 18px;
    width: 18px;
}

/* .room-paper:hover {
    filter: opacity(.5);
} */

.container {
    scroll-snap-type: y mandatory;
}

.react-rotating-text-cursor {
    animation: blinking-cursor 0.8s cubic-bezier(0.68, 0.01, 0.01, 0.99) 0s infinite;
}

@keyframes blinking-cursor {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}