.business {
    background-color: #1C2440;
    min-height: 120vh;
    width: 100vw;
}

.garden {
    background-color: #D7A253;
    height: 90vh;
    width: 100vw;
}

.hotel-img {
    width: 100%;
    /* height: 345px; */
}

.stroke-text {
    text-align: center;
}

.square-img {
    width: 345px;
    height: 345px;
}

.parallax-img-container {
    display: flex;
    flex-flow: row wrap;
}

.landing-container{
    position: absolute;
    top: 20vh;
    width: 100%;
    /* left: 20vw; */
}

.container {
    overflow-x: hidden;
}

.circular-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 200px;
    position: fixed;
    top: calc(2vh);
    right: 0;
}

.text {
    font-weight: 300;
    font-size: 0.5rem;
    color: #D7A253;
    letter-spacing: 0.5rem;
}

.landing-img {
    max-height: 30vh;
    max-width: 20vw
}